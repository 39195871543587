@mixin dmsans {
  font-family: 'DM Sans', sans-serif;
  font-size: 0.875rem;
}

$mobile: 414px;
$tablet: 768px;
$medium: 1200px;
$desktop: 1440px;
$large: 1600px;

:root {
  --black: #000;
  --gradient: linear-gradient(
    180deg,
    rgba(20, 20, 20, 1) 0%,
    rgba(45, 45, 45, 0.6) 20%,
    rgba(20, 20, 20, 1) 100%
  );
}

.new-sec {
  min-height: 100rem;
  padding: 1rem 0 8rem;

  &.bg-black {
    background-color: var(--black);
    color: var(--white);
  }
}

.dashboard {
  width: 100%;
  max-width: 162rem;
  margin: 0 auto;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include dmsans;

  h1,
  h2,
  h3,
  h4,
  h5 {
    @include dmsans;
  }

  .tns-outer {
    width: 100%;
  }

  h2 {
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 3rem;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: $desktop) {
      font-size: 8.5rem;
    }
  }

  &-data {
    width: 100%;
    max-height: 100%;
    margin-top: 2rem;
    padding: 0;

    @media screen and (min-width: $desktop) {
      height: 75rem;
    }

    > li {
      height: 100%;

      &.mobile-slide {
        display: none !important;

        @media screen and (min-width: $desktop) {
          display: block;
        }

        .index {
          padding: 4rem 2rem 0;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          background: none;
        }
      }
    }
  }

  .data-panel {
    width: 100%;
    max-width: calc(100% - 4rem);
    height: 100%;
    min-height: 72rem;
    margin: 0 auto;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4rem;
    background: #141414;

    @media screen and (min-width: $medium) {
      max-width: calc(100% - 8rem);
    }

    @media screen and (min-width: $desktop) {
      min-height: 75rem;
      padding: 4rem 0;
      justify-content: space-between;
      border-radius: 8rem;
    }

    &.plain {
      background: var(--gradient);
    }

    &.with-bg {
      padding: 2rem 2.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
      background: #141414 no-repeat 50%;
      background-size: cover;

      @media screen and (min-width: $medium) {
        padding: 4rem 6rem;
      }

      video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        object-fit: cover;
        object-position: center;
        pointer-events: none;
        border-radius: 4rem;

        @media screen and (min-width: $desktop) {
          height: auto;
          border-radius: 8rem;
        }
      }

      > div {
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }

    &.live-data {
      justify-content: center;

      h4 {
        font-size: 1.6rem;
        text-transform: uppercase;

        @media screen and (min-width: $tablet) {
          font-size: 2rem;
        }

        @media screen and (min-width: $medium) {
          font-size: 2.7rem;
        }

        strong {
          margin-right: 0.5rem;
          font-size: 2.7rem;
          font-weight: 400;
          color: #3fb888;

          @media screen and (min-width: $tablet) {
            margin-right: 1rem;
            font-size: 4rem;
          }

          @media screen and (min-width: $medium) {
            margin-right: 2rem;
            font-size: 9rem;
          }
        }
      }

      .tile {
        &s {
          margin: 2rem 0 0;
          padding: 0;
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 2.5rem;
          row-gap: 5rem;
          list-style-type: none;

          @media screen and (min-width: $tablet) {
            grid-template-columns: 1fr 1fr 1fr;
          }

          @media screen and (min-width: $desktop) {
            display: flex;
            gap: 5rem;
          }

          &.outdoor {
            li {
              @media screen and (min-width: $medium) {
                width: 24rem;
              }
            }
          }

          li {
            text-align: center;

            @media screen and (min-width: $medium) {
              width: 20rem;
              margin: 0 auto;
            }
          }

          figure {
            height: 10rem;
            margin: 0;
            padding: 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background-color: #141414;
            background: var(--gradient);
            border-radius: 2rem;

            @media screen and (min-width: $medium) {
              height: 18rem;
              padding: 2rem;
              border-radius: 3rem;
            }

            figcaption {
              margin-top: 1rem;
              flex-grow: 0;
              font-size: 1.25rem;
              line-height: 1.2;

              @media screen and (min-width: $medium) {
                margin-top: 2rem;
                font-size: 3rem;
              }
            }

            > div {
              display: flex;
              justify-content: center;
              flex-grow: 1;

              > img {
                width: 100%;
                max-width: 100%;
                height: auto;
                display: block;

                @media screen and (min-width: $medium) {
                  display: none;
                }

                &.large {
                  display: none;

                  @media screen and (min-width: $medium) {
                    display: block;
                  }
                }
              }
            }

            + p {
              margin: 1rem 0 0;
              font-size: 1.5rem;

              @media screen and (min-width: $medium) {
                margin-top: 2rem;
                font-size: 2rem;
              }
            }
          }
        }
      }
    }

    &.smaller {
      .top-stat {
        max-width: 60%;

        @media screen and (min-width: $desktop) {
          max-width: 44%;
        }
      }

      .bottom-stat {
        max-width: 70%;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: $medium) {
          flex-direction: row;
          gap: 4rem;
        }

        &.larger {
          max-width: 50%;

          &.unset {
            max-width: unset;
          }

          @media screen and (min-width: $desktop) {
            max-width: 72% !important;
          }

          h4 {
            font-size: 3.8rem;

            @media screen and (min-width: $medium) {
              font-size: 7rem;
            }

            @media screen and (min-width: $desktop) {
              font-size: 12rem;
            }
          }
        }

        p {
          font-size: 1.2rem;

          @media screen and (min-width: $medium) {
            font-size: 2.4rem;
          }

          @media screen and (min-width: $desktop) {
            font-size: 3rem;
          }
        }
      }

      .stat {
        p {
          &:first-child {
            font-size: 1.4rem;

            @media screen and (min-width: $desktop) {
              font-size: 2.5rem;
            }
          }
        }
      }
    }

    &.larger {
      .top-stat {
        max-width: 80%;

        @media screen and (min-width: $desktop) {
          max-width: 50%;
        }
      }

      .bottom-stat {
        max-width: 50%;

        @media screen and (min-width: $desktop) {
          max-width: 40%;
        }
      }

      .stat {
        h4 {
          font-size: 3.8rem;

          @media screen and (min-width: $medium) {
            font-size: 7rem;
          }

          @media screen and (min-width: $desktop) {
            font-size: 10rem;
          }
        }

        p {
          &:first-child {
            font-size: 4rem;
          }
        }
      }
    }

    h3 {
      font-size: 1.8rem;
      font-weight: 500;
      text-transform: uppercase;

      @media screen and (min-width: $medium) {
        font-size: 3.2rem;
      }

      @media screen and (min-width: $desktop) {
        font-size: 5.8rem;
      }

      + .charts {
        margin-top: 1.5rem;

        @media screen and (min-width: $desktop) {
          margin-top: 0;
        }
      }
    }

    .stat {
      flex-grow: 1;
      flex-basis: 0;

      h4 {
        font-size: 3.8rem;

        @media screen and (min-width: $medium) {
          font-size: 6rem;
        }

        @media screen and (min-width: $desktop) {
          font-size: 9.5rem;
        }

        + p {
          font-size: 1.6rem;
          line-height: 1.25;
          text-transform: uppercase;

          @media screen and (min-width: $medium) {
            font-size: 2.5rem;
          }

          @media screen and (min-width: $desktop) {
            font-size: 4rem;
          }
        }
      }

      p {
        margin-bottom: 0;

        &:first-child {
          margin: 0;
          text-transform: uppercase;
        }
      }
    }

    .top-stat {
      align-self: flex-start;
    }

    .bottom-stat {
      align-self: flex-end;
    }
  }

  .charts {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.4rem;

    @media screen and (min-width: $medium) {
      padding-bottom: 4rem;
      flex-direction: row;
      align-items: unset;
      gap: 8rem;
    }

    @media screen and (min-width: $desktop) {
      gap: 16rem;
    }

    .chart {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      text-align: center;

      @media screen and (min-width: $medium) {
        max-width: 21rem;
      }

      h4 {
        margin: 0;
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;

        @media screen and (min-width: $medium) {
          font-size: 2.5rem;
        }
      }

      figure {
        width: 100%;
        max-width: 14.5rem;
        margin: 0 auto;
        position: relative;

        @media screen and (min-width: $medium) {
          max-width: unset;
        }

        figcaption {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            font-size: 2.3rem;
            line-height: 1;

            @media screen and (min-width: $medium) {
              font-size: 2.7rem;
            }
          }
        }

        + h4 {
          margin-top: 3rem;

          @media screen and (min-width: $medium) {
            display: block;
          }

          &.mobile-hidden {
            display: none;

            @media screen and (min-width: $medium) {
              display: block;
            }
          }
        }
      }

      &.large {
        @media screen and (min-width: $medium) {
          max-width: 32rem;
        }

        figure {
          max-width: 11rem;

          @media screen and (min-width: $medium) {
            max-width: unset;
          }

          + h4 {
            margin-top: 1.5rem;
            font-size: 2rem;
            line-height: 0.875;

            @media screen and (min-width: $medium) {
              margin-top: 3rem;
              font-size: 4rem;
            }

            span {
              font-size: 1.6rem;
              font-weight: 400;

              @media screen and (min-width: $medium) {
                font-size: 3.2rem;
              }
            }

            + p {
              margin: 1rem 0 0;
              font-size: 1.4rem;

              @media screen and (min-width: $medium) {
                margin-top: 2rem;
                font-size: 1.8rem;
              }
            }
          }
        }

        figcaption {
          span {
            font-size: 3rem;

            @media screen and (min-width: $medium) {
              font-size: 5.3rem;
            }
          }
        }

        .donut {
          width: 22.5rem;
          max-width: 100%;
          height: auto;
          margin: 0 auto;

          &-wrapper {
            margin: 0;
          }

          svg {
            transform: scale(1.325);
          }
        }
      }

      .title-wrapper {
        display: flex;
        align-items: center;
        flex-grow: 1;
      }

      .donut {
        width: 20rem;
        max-width: 100%;
        height: auto;

        &-wrapper {
          margin-top: 1.5rem;

          @media screen and (min-width: $medium) {
            margin-top: 3rem;
          }
        }

        svg {
          transform: scale(1.2);
        }
      }
    }
  }

  .index {
    width: 100%;
    max-width: 106rem;
    height: 23rem;
    padding: 0 6rem;
    display: none;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.8rem;
    background: #141414;
    background: var(--gradient);

    @media screen and (min-width: $medium) {
      display: flex;
    }

    > * {
      flex-grow: 1;
      flex-basis: 0;
      text-align: center;
    }

    span {
      font-size: 2.5rem;
      line-height: 1.2;

      @media screen and (min-width: $desktop) {
        font-size: 2.1rem;
      }
    }

    h5 {
      color: #52dc81;
      font-size: 13rem;
      font-weight: 700;

      @media screen and (min-width: $desktop) {
        font-size: 11rem;
      }
    }
  }

  .tns {
    &-outer {
      position: relative;
    }

    &-controls {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      button {
        &[data-controls] {
          width: 2rem;
          height: 2rem;
          position: relative;
          background: none;
          border: none;
          color: transparent;

          @media screen and (min-width: $desktop) {
            width: 4rem;
            height: 4rem;
          }

          &::before {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-bottom: 2px solid #fff;
          }
        }

        &[data-controls='prev'] {
          margin-left: 2rem;

          @media screen and (min-width: $desktop) {
            margin-left: 6rem;
          }

          @media screen and (min-width: $large) {
            margin-left: -4rem;
          }

          &::before {
            border-left: 2px solid #fff;
            transform: rotate(45deg);
          }
        }

        &[data-controls='next'] {
          margin-right: 2rem;

          @media screen and (min-width: $desktop) {
            margin-right: 6rem;
          }

          @media screen and (min-width: $large) {
            margin-right: -4rem;
          }

          &::before {
            border-right: 2px solid #fff;
            transform: rotate(-45deg);
          }
        }
      }
    }

    &-slide-active {
      video {
        object-fit: cover;
      }
    }
  }
}
